import request from '@/router/axios';
var textileBaseUrl = "http://localhost:5202/";
// 引入使用    import { TextilePurchaseOrdeItemIList, TextilePurchaseOrdeItemIById,TextilePurchaseOrdeItemIAdd, TextilePurchaseOrdeItemIEdit, TextilePurchaseOrdeItemIDel} from "@/api/Purchase/TextilePurchaseOrdeItemApi";
//显示列表查询
export const listInfo = (ParamDocumentNo, DocumentDate, DocumentNo, DocumentType, Warehouse, InoutStockType, page, pageSize) => {
    return request({
        url: textileBaseUrl + "st/Stock/GetStockInoutDetails",
        method: "get",
        params: {
            "paramDocumentNo": ParamDocumentNo,
            "documentStartDate": DocumentDate,
            "documentEndDate": DocumentDate,
            "documentNo": DocumentNo,
            "documentType": DocumentType,
            "warehouse": Warehouse,
            "inoutStockType": InoutStockType,
            "page": page,
            "pageSzie": pageSize
        }
    })
}

//根据ID获取单条信息
export const TextilePurchaseOrdeItemIById = (id) => {
    return request({
        url: textileBaseUrl + "st/Stock/GetStockInoutDetailById",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const addDetail = (row) => {

    console.log("结束",row,textileBaseUrl);
    return request({
        url: textileBaseUrl + "st/Stock/PostStockInoutDetail",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const editDetail = (row) => {
    console.log("row",row);
    return request({
        url: textileBaseUrl + "st/Stock/PutStockInoutDetail",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const delDetail = (id) => {
    return request({
        url: textileBaseUrl + "st/Stock/DeleteStockInoutDetail",
        method: "delete",
        params: {
            id
        }
    })
}



